import { useAuth } from "@clerk/clerk-react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useContext, useState } from "react";
import { fetchOrganizations } from "../../api/organizationAPI";
import { fetchTeams } from "../../api/teamAPI";
import { createTeam } from "../../api/teamAPI";
import { MyContext } from "../../context/Context";
import CircularIndeterminate from "../CircularIndeterminate";

interface OrganizationListProps {
  setCurrentOrganizationId?: (id: string) => void;
}
interface TeamMutationProps {
  organizationId: string;
  title: string;
}
const OrganizationList: React.FC<OrganizationListProps> = () => {
  const { getToken } = useAuth();
  const { userMutation } = useContext(MyContext);
  const queryClient = useQueryClient();
  const [expandedOrganizationId, setExpandedOrganizationId] = useState<
    null | string
  >(null);
  const mutation = useMutation({
    mutationFn: (team: TeamMutationProps) =>
      (async () => {
        const freshToken = await getToken();
        if (!freshToken) {
          throw new Error("could not generate a token for this request");
        }
        return createTeam(team.organizationId, team.title, freshToken);
      })(),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["teams"] });
    },
    onError: (error: Error) => {
      console.error("team error", error);
    },
  });

  const { isPending, error, data } = useQuery({
    queryKey: ["organizations"],
    queryFn: () =>
      (async () => {
        const freshToken = await getToken();
        if (!freshToken) {
          throw new Error("could not generate a token for this request");
        }
        return fetchOrganizations(freshToken);
      })(),
  });
  const { data: dataTeams } = useQuery({
    queryKey: ["teams", expandedOrganizationId],
    queryFn: () =>
      (async () => {
        const freshToken = await getToken();
        if (!freshToken) {
          throw new Error("could not generate a token for this request");
        }
        return fetchTeams(expandedOrganizationId as string, freshToken);
      })(),

    enabled: !!expandedOrganizationId,
  });

  const handleCreateTeam = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const title = formData.get("teamName") as string;
    const organizationId = formData.get("organizationId") as string;
    if (!title || !organizationId) {
      throw new Error("data was missing");
    }
    mutation.mutate({ organizationId, title });
  };

  return (
    <div>
      <h2 className="text-lg">Organization List</h2>
      {isPending && <CircularIndeterminate />}
      {error && <p>An error has occurred: {error.message}</p>}
      <div className="flex flex-col gap-2">
        {data &&
          data?.map((organization, index) => (
            <div key={index}>
              <p>
                Click on the organization name to join that organization. Then
                click view teams and click on the team you want to join to join
                that team.
              </p>
              <div className="flex flex justify-between">
                <div>
                  <button
                    className="btn-henna"
                    onClick={() =>
                      userMutation.mutate({
                        userFields: { selectedOrganizationId: organization.id },
                      })
                    }
                  >
                    Join {organization.title}
                  </button>
                </div>
                <div>
                  <button
                    onClick={() => setExpandedOrganizationId(organization.id)}
                    className="btn-henna"
                  >
                    View Teams belonging to {organization.title}
                  </button>
                </div>
              </div>

              {organization.id === expandedOrganizationId && dataTeams && (
                <div className="flex flex-col gap-2 ml-5">
                  <h3 className="text-lg">
                    These are the teams belonging to {organization.title}
                  </h3>
                  {dataTeams.map((team, index2) => (
                    <div key={index2}>
                      <button
                        onClick={() =>
                          userMutation.mutate({
                            userFields: { selectedTeamId: team.id },
                          })
                        }
                        className="btn-henna"
                      >
                        Join {team.title}
                      </button>
                    </div>
                  ))}
                </div>
              )}

              {userMutation.isPending && <p>Updating your account... </p>}
              {userMutation.error && <p>{userMutation.error.message} </p>}
              {userMutation.isSuccess && <p>Success! </p>}
              <form
                onSubmit={handleCreateTeam}
                className="my-2 bt-solid bt-black"
              >
                <label>Create a team in {organization.title}</label>
                <input name="teamName" />
                {mutation.isPending && <p>Creating a new team...</p>}
                {mutation.error && <p>{mutation.error.message}</p>}
                <input hidden name="organizationId" value={organization.id} />
                <button className="btn-henna">Submit</button>{" "}
              </form>
            </div>
          ))}
      </div>
    </div>
  );
};

export default OrganizationList;
