import axios from "axios";
import { Message } from "../types/models/Message";

const backendURL = process.env.REACT_APP_BACKEND_URL;

export async function createMessage(
  organizationId: string,
  teamId: string,
  conversationId: string,
  text: string,
  token: string,
): Promise<Message> {
  const response = await axios.post(
    `${backendURL}/organization/${organizationId}/team/${teamId}/conversation/${conversationId}/message`,
    {
      text,
    },
    {
      headers: {
        "Content-Type": "application/json", // Example header
        Authorization: `Bearer ${token}`,
      },
    },
  );
  if (response?.data?.message) {
    return response?.data?.message as Message;
  } else {
    throw new Error("did not return a message");
  }
}

export async function fetchMessages(
  organizationId: string,
  teamId: string,
  conversationId: string,
  token: string,
): Promise<Message[]> {
  const response = await axios.get(
    `${backendURL}/organization/${organizationId}/team/${teamId}/conversation/${conversationId}/message`,
    {
      headers: {
        "Content-Type": "application/json", // Example header
        Authorization: `Bearer ${token}`,
      },
    },
  );
  if (response?.data?.message) {
    return response.data.message;
  } else {
    throw new Error("Failed to fetch messages");
  }
}

export async function fetchMessageById(
  organizationId: string,
  teamId: string,
  conversationId: string,
  messageId: string,
  token: string,
): Promise<Message> {
  const response = await axios.get(
    `${backendURL}/organization/${organizationId}/team/${teamId}/conversation/${conversationId}/message/${messageId}`,
    {
      headers: {
        "Content-Type": "application/json", // Example header
        Authorization: `Bearer ${token}`,
      },
    },
  );
  if (response?.data?.message) {
    return response.data.message;
  } else {
    throw new Error("Message not found");
  }
}

export async function updateMessage(
  organizationId: string,
  teamId: string,
  conversationId: string,
  messageId: string,
  text: string,
  token: string,
): Promise<Message> {
  const response = await axios.put(
    `${backendURL}/organization/${organizationId}/team/${teamId}/conversation/${conversationId}/message/${messageId}`,
    { text },
    {
      headers: {
        "Content-Type": "application/json", // Example header
        Authorization: `Bearer ${token}`,
      },
    },
  );
  if (response?.data?.message) {
    return response.data.message;
  } else {
    throw new Error("Failed to update message");
  }
}

export async function deleteMessage(
  organizationId: string,
  teamId: string,
  conversationId: string,
  messageId: string,
  token: string,
): Promise<void> {
  const response = await axios.delete(
    `${backendURL}/organization/${organizationId}/team/${teamId}/conversation/${conversationId}/message/${messageId}`,
    {
      headers: {
        "Content-Type": "application/json", // Example header
        Authorization: `Bearer ${token}`,
      },
    },
  );
  if (response.status !== 200) {
    throw new Error("Failed to delete message");
  }
}
