import { createContext } from "react";
import { ContextProps } from "../types/models/Context";
import { UserMutationProps } from "../types/models/User";

const initialValue: ContextProps = {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  userMutation: (obj: UserMutationProps) => {},
  user: null,
};
export const MyContext = createContext(initialValue);
