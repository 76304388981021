import React, { useEffect, useState } from "react";
import hennaDesktopLogo from "../../components/logos/Henna-Background-desktop.png";
import { useAuth } from "@clerk/clerk-react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { JoinOrgAndTeamWithToken } from "../../api/userAPI";

const JoinOrgAndTeamPage: React.FC = () => {
  const [params] = useSearchParams();
  const queryClient = useQueryClient();
  const { getToken } = useAuth();
  const navigate = useNavigate();
  const [isError, setIsError] = useState(false);
  const [retryCount, setRetryCount] = useState(0);
  const maxRetries = 10;
  useEffect(() => {
    const jwtToken = params.get("token");
    if (jwtToken) {
      mutation.mutate(jwtToken);
    }
  }, [params]);

  const mutation = useMutation({
    mutationFn: (jwtToken: string) =>
      (async () => {
        const freshToken = await getToken();
        if (!freshToken) {
          throw new Error("could not generate a token for this request");
        }
        return JoinOrgAndTeamWithToken(freshToken, jwtToken);
      })(),
    onSuccess: (user) => {
      queryClient.invalidateQueries({ queryKey: ["userContext"] });
      navigate("/chat/dashboard");
    },
    onError: (error: Error) => {
      const jwtToken = params.get("token");
      if (retryCount < maxRetries && jwtToken) {
        setTimeout(() => {
          setRetryCount((current) => current + 1);
          mutation.mutate(jwtToken);
        }, 1000 * retryCount);
        console.log("v", retryCount);
      } else {
        setIsError(true);
      }
      console.error("unable to update your organization and team");
    },
  });

  return (
    <div>
      <div className="flex h-screen font-sans">
        <div className="w-8/12">
          <img
            src={hennaDesktopLogo}
            style={{ width: "100%", height: "100%" }}
            alt="Henna's premier desktop logo"
          />
        </div>
        <div className="w-4/12">
          {!isError && <span>We are processing your request...</span>}
          {isError && (
            <span>
              We were unable to set your organization and team. Please contact
              your team leader and then, on the dashboard, request to join your
              organization and team if you know the organization name and team
              name.
              <Link to="/chat/dashboard" className="text-blue-300">
                {" "}
                Dashboard
              </Link>
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default JoinOrgAndTeamPage;
