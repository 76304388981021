import React from "react";
import { Button } from "react-bootstrap";
import ButtonOutline from "../ButtonOutline";
interface NewConversationProps {
  organizationId: string;
  conversationsData: any;
  setCurrentConversationId: (id: string) => void;
  mutation: any;
  isGetFeedbackEnabled: boolean;
  isOrgValuesEnabled: boolean;
  handleUpdateOrCreateConversation: (
    e?: React.FormEvent,
    type?: string,
    action?: string,
  ) => void;
}

const NewConversation: React.FC<NewConversationProps> = ({
  organizationId,
  mutation,
  handleUpdateOrCreateConversation,
  isGetFeedbackEnabled,
  isOrgValuesEnabled,
}) => {
  return (
    <div>
      <form
        onSubmit={(e) => handleUpdateOrCreateConversation(e, "discussValues")}
        className="flex m-5"
      >
        <ButtonOutline
          type="submit"
          disabled={mutation.isPending}
          text="Talk about Values"
        ></ButtonOutline>
      </form>
      <form
        onSubmit={(e) =>
          handleUpdateOrCreateConversation(e, undefined, "update")
        }
        className="flex m-5"
      >
        <ButtonOutline
          text="Start talking about Organization Values"
          disabled={!isOrgValuesEnabled}
        />
      </form>
      <form
        onSubmit={(e) => handleUpdateOrCreateConversation(e, "feedback")}
        className="flex m-5"
      >
        <ButtonOutline
          type="submit"
          disabled={!isGetFeedbackEnabled}
          // disabled={mutation.isPending}
          text="Get Feedback"
        ></ButtonOutline>
      </form>{" "}
      {mutation.isPending && <p>We are working on completing this action</p>}
      {mutation.isError && (
        <p>{mutation?.error?.message ?? "There was an unexpected error"}</p>
      )}
      {/* {mutation.isSuccess && (
        <p>This action has been completed successfully!</p>
      )} */}
    </div>
  );
};

export default NewConversation;
