import { useAuth, useClerk } from "@clerk/clerk-react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { deleteUser, updateUser } from "../../api/userAPI";
import ButtonOutline from "../../components/ButtonOutline";
import Layout from "../../components/layout/ChatLayout";
import JoinRequestForm from "../../components/chat/JoinRequestForm";
import MyJoinRequestList from "../../components/chat/MyJoinRequestList";
import { MyContext } from "../../context/Context";
import { Organization } from "../../types/models/Organization";
import { Team } from "../../types/models/Team";
import { User } from "../../types/models/User";

interface AsideProps {
  handleShowRequests: () => void;
  handleShowJoinRequestForm: () => void;
  handleShowAccountInformation: () => void;
  handleShowChangeTeamOrOrganization: () => void;
}
const Aside: React.FC<AsideProps> = ({
  handleShowRequests,
  handleShowJoinRequestForm,
  handleShowAccountInformation,
  handleShowChangeTeamOrOrganization,
}) => (
  <div className="flex flex-col gap-2">
    <ButtonOutline
      handleClick={handleShowAccountInformation}
      text="Account Information"
    />
    <ButtonOutline handleClick={handleShowRequests} text="Show Requests" />
    <ButtonOutline
      handleClick={handleShowJoinRequestForm}
      text="Request to Join a new Team"
    />
    <ButtonOutline
      handleClick={handleShowChangeTeamOrOrganization}
      text="Change Team or Organization"
    />
  </div>
);
type mutationProps = {
  type: string;
  updateData: Partial<User>;
};

const AccountPage: React.FC = () => {
  const { user } = useContext(MyContext);
  const [showRequests, setShowRequests] = useState(false);
  const [showJoinRequestForm, setShowJoinRequestForm] = useState(false);
  const [showAccountInformation, setShowAccountInformation] = useState(true);
  const [showChangeTeamOrOrganization, setShowChangeTeamOrOrganization] =
    useState(false);
  const handleShowRequests = () => {
    setShowRequests(true);
    setShowJoinRequestForm(false);
    setShowAccountInformation(false);
    setShowChangeTeamOrOrganization(false);
  };
  const handleShowJoinRequestForm = () => {
    setShowJoinRequestForm(true);
    setShowRequests(false);
    setShowAccountInformation(false);
    setShowChangeTeamOrOrganization(false);
  };
  const handleShowAccountInformation = () => {
    setShowAccountInformation(true);
    setShowRequests(false);
    setShowJoinRequestForm(false);
    setShowChangeTeamOrOrganization(false);
  };
  const handleShowChangeTeamOrOrganization = () => {
    setShowChangeTeamOrOrganization(true);
    setShowAccountInformation(false);
    setShowRequests(false);
    setShowJoinRequestForm(false);
  };

  const renderAside = () => (
    <Aside
      handleShowRequests={handleShowRequests}
      handleShowJoinRequestForm={handleShowJoinRequestForm}
      handleShowAccountInformation={handleShowAccountInformation}
      handleShowChangeTeamOrOrganization={handleShowChangeTeamOrOrganization}
    />
  );
  const { getToken } = useAuth();
  const { signOut } = useClerk();
  const queryClient = useQueryClient();

  const userMutation = useMutation({
    mutationFn: (mutationProps: mutationProps) =>
      (async (type: string, updateData: Partial<User>) => {
        const freshToken = await getToken(); // Retrieve fresh token asynchronously
        if (!freshToken) {
          throw new AxiosError(
            "Your authentication token could not be accessed",
          );
        }
        if (!user?.id) {
          throw new Error("user id not provided...");
        }
        if (type === "delete") {
          return deleteUser(user.id, freshToken);
        } else if (type === "update") {
          return updateUser(user.id, updateData, freshToken);
        }
      })(mutationProps.type, mutationProps.updateData),
    onSuccess: async (response: AxiosResponse | User | undefined) => {
      if (response && "status" in response) {
        if (response?.status === 204) {
          await signOut();
        }
      } else {
        queryClient.invalidateQueries({ queryKey: ["userContext"] });
      }
    },
    onError: (error: Error) => {
      console.error("User update error", error);
    },
  });
  const handleDeleteAccount = () => {
    userMutation.mutate({ type: "delete", updateData: {} });
  };
  const handlechangeSelectedOrganization = (
    e: React.MouseEvent<HTMLButtonElement>,
    organization: Organization,
  ) => {
    userMutation.mutate({
      type: "update",
      updateData: { selectedOrganizationId: organization.id },
    });
  };

  const handlechangeSelectedTeam = (
    e: React.MouseEvent<HTMLButtonElement>,
    team: Team,
  ) => {
    userMutation.mutate({
      type: "update",
      updateData: { selectedTeamId: team.id },
    });
  };
  return (
    <Layout aside={renderAside}>
      <div className="flex w-full justify-center">
        <div className="flex flex-col gap-2 p-2 m-2 md:w-1/2">
          <h1 className="text-2xl">My Account</h1>
          {showAccountInformation && (
            <div>
              <h3 className="text-xl">Account Information</h3>
              <p>
                Name: {user?.firstName} {user?.lastName}
              </p>
              <p>Email: {user?.email}</p>
              <p>Current Organization: {user?.selectedOrganization?.title}</p>
              <p>Current Team: {user?.selectedTeam?.title}</p>
              <div className="mt-10">
                <button onClick={handleDeleteAccount} className="btn-henna">
                  delete this account{" "}
                </button>
              </div>
            </div>
          )}
          {showChangeTeamOrOrganization && (
            <div>
              <p>
                {`If
              you are a member of multiple teams or organizations, then those
              will show up here. You can change your active team
              and organization by clicking on the options below. If you believe
              an organization or team is missing, click 'request to join a new
              team.`}
              </p>
              <p>Current Organization: {user?.selectedOrganization?.title}</p>
              <p>Current Team: {user?.selectedTeam?.title}</p>
              <h3 className="text-lg">Change my current organization:</h3>

              {user?.organizations?.map((organization, index) => (
                <button
                  className="App-rounded-button"
                  key={index}
                  onClick={(e) =>
                    handlechangeSelectedOrganization(e, organization)
                  }
                >
                  {organization.title}
                </button>
              ))}
              <h3 className="text-lg">Change my current Team:</h3>
              {user?.teams?.map((team, index) => (
                <button
                  className="App-rounded-button"
                  key={index}
                  onClick={(e) => handlechangeSelectedTeam(e, team)}
                >
                  {team.title}
                </button>
              ))}
            </div>
          )}

          {showJoinRequestForm && <JoinRequestForm />}
          {showRequests && <MyJoinRequestList />}
        </div>
      </div>
    </Layout>
  );
};

export default AccountPage;
