import React from "react";

interface MessageSenderHeaderProps {
  sender: string | null | undefined;
  initials?: string;
}

const MessageSenderHeader: React.FC<MessageSenderHeaderProps> = ({
  sender,
  initials,
}) => {
  return (
    <div className={`flex ${sender === "You" ? "justify-end" : ""}`}>
      {sender === "You" && (
        <div className="profile-pic ml-3 text-slate-100 items-center flex justify-center">
          {initials ?? ""}
        </div>
      )}
      {sender !== "You" && (
        <div className="henna-profile-pic overflow-hidden">
          <img src="../../henna-profile.png" className="henna-profile-image" />
        </div>
      )}
      {/* <p className="font-sans font-bold text-lg">{sender}</p> */}
    </div>
  );
};

export default MessageSenderHeader;
