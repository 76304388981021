import { SignOutButton } from "@clerk/clerk-react";
import { useState, useEffect, useRef, useContext } from "react";
// import { ReactComponent as NotificationBell } from "./logos/notificationBell.svg";
import { useUser } from "@clerk/clerk-react";
import { Button } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import { MyContext } from "../context/Context";
import { Link } from "react-router-dom";

const AccountSection = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleRef = useRef<HTMLDivElement | null>(null); // Set the type to HTMLDivElement
  const { user: clerkUser } = useUser();
  const { user } = useContext(MyContext);
  // Toggle dropdown
  const toggleDropdown = () => setIsOpen(!isOpen);

  // Close dropdown if clicked outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        toggleRef.current &&
        !toggleRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <div ref={toggleRef} className="w-full block">
      <div className="top-0 right-0 flex items-center font-sans py-5 px-2 fixed">
        <Button onClick={toggleDropdown}>
          <div className="flex">
            <div className="profile-pic ml-3 text-slate-100 items-center flex justify-center">
              {clerkUser?.firstName?.[0]} {clerkUser?.lastName?.[0]}
            </div>
            <div className="hidden lg:flex lg:flex-col lg:items-start">
              <span className="break-words font-bold">
                {clerkUser?.fullName ??
                  clerkUser?.emailAddresses?.[0].emailAddress}
              </span>
              <span className="break-words font-bold">
                {user?.selectedTeam?.title}
              </span>
            </div>
          </div>
        </Button>
      </div>
      {isOpen && (
        <div className="fixed top-20 right-2 lg:right-10 z-10 min-w-4 flex flex-col bg-gray-50 drop-shadow-xl">
          <Link
            to="/chat/account"
            className="hover:bg-gray-200 py-3 px-10 w-full"
          >
            Account
          </Link>
          <div className="hover:bg-gray-200 py-3 px-10">
            <SignOutButton />
          </div>
        </div>
      )}
    </div>
  );
};
// Figure out how to style the SignOutButton component later so we don't have to wrap it with a div for styling
export default AccountSection;
