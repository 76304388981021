import React from "react";

interface MessageListProps {
  organizationName?: string;
  teamName?: string;
  firstName: string;
}
const WelcomeBanner: React.FC<MessageListProps> = ({
  organizationName,
  teamName,
  firstName,
}) => {
  return (
    <div className="grid grid-cols-3 mt-20 p-5 m-2 border-2 border-solid-henna-border-gray rounded max-w-[830px] self-center">
      <div className="col-span-2 flex flex-col gap-2">
        <span className="text-3xl font-extrabold">Hey {firstName}.</span>
        {organizationName && (
          <div className="flex flex-col md:flex-row gap-2 md:items-center">
            <span className="text-2xl font-bold text-henna-green">
              {organizationName}
            </span>
            <span>YOUR ORGANIZATION</span>
          </div>
        )}
        {!organizationName && (
          <div className="flex gap-2 items-center">
            <span className="text-2xl font-bold text-henna-green">
              {"You haven't joined an organization yet..."}
            </span>
          </div>
        )}
        {teamName && (
          <div className="flex flex-col md:flex-row gap-2 md:items-center">
            <span className="text-2xl font-bold text-henna-green">
              {teamName}
            </span>
            <span>YOUR TEAM</span>
          </div>
        )}
        {!teamName && (
          <div className="flex gap-2 items-center">
            <span className="text-2xl font-bold text-henna-green">
              {"You haven't joined a team yet..."}
            </span>
          </div>
        )}
      </div>
      <div className="col-span-1">
        <img
          src="../../woman_with_ball.png"
          className="relative lg:-top-[73px]"
        />
      </div>
    </div>
  );
};

export default WelcomeBanner;
