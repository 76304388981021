import { useAuth } from "@clerk/clerk-react";
import { useQueryClient, useMutation, useQuery } from "@tanstack/react-query";
import { useState } from "react";
import {
  createJoinRequest,
  fetchMyJoinRequests,
} from "../../api/joinRequestAPI";
import { JoinRequest } from "../../types/models/JoinRequest";

const MyJoinRequestList: React.FC = () => {
  const { getToken } = useAuth();

  const { isPending, error, data } = useQuery({
    queryKey: ["myJoinRequests"],
    queryFn: () =>
      (async () => {
        const freshToken = await getToken();
        if (!freshToken) {
          throw new Error("could not generate a token for this request");
        }
        return fetchMyJoinRequests(freshToken);
      })(),
  });
  return (
    <div className="flex flex-col gap-2 border-2 border-slate-800 p-3">
      <h3 className="text-lg">My Pending Requests: </h3>

      {data
        ?.filter((request) => request.status === "PENDING")
        ?.map((request, index) => (
          <div key={index}>
            You have requested to join organization:{" "}
            {request.organization?.title} and team: {request.team?.title}.
          </div>
        ))}
      <h3 className="text-lg">My Accepted Requests: </h3>

      {data
        ?.filter((request) => request.status === "APPROVED")
        ?.map((request, index) => (
          <div key={index}>
            Your request to join organization:
            {request.organization?.title} and team: {request.team?.title} has
            been approved.
          </div>
        ))}
      <h3 className="text-lg">My Rejected Requests: </h3>

      {data
        ?.filter((request) => request.status === "REJECTED")
        ?.map((request, index) => (
          <div key={index}>
            Your request to join organization:
            {request.organization?.title} and team: {request.team?.title} has
            been rejected.
          </div>
        ))}
    </div>
  );
};

export default MyJoinRequestList;
