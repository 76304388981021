import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { useUser } from "@clerk/clerk-react";
import { MyContext } from "../../context/Context";
import CircularIndeterminate from "../CircularIndeterminate";

export const ProtectedRouteIsAdmin = ({
  children,
}: {
  children: JSX.Element;
}) => {
  const { isSignedIn, isLoaded } = useUser();
  const { user } = useContext(MyContext);
  if (!isSignedIn) {
    return <CircularIndeterminate />;
  }
  if (!user) {
    return <CircularIndeterminate />;
  }
  // fix me-take out the top accepted roles...
  const acceptedRoles = [
    // "DEFAULTUSER",
    // "TEAMLEADER",
    // "ORGANIZATIONLEADER",
    "ADMIN",
    "GLOBALADMIN",
  ];

  if (!isSignedIn && isLoaded) {
    return <Navigate to="/auth" replace />;
  }
  if (user && user.role) {
    if (acceptedRoles.includes(user.role.title)) {
      return children;
    }
  }
  return <Navigate to="/chat/dashboard" replace />;
};
