import React, { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { createOrganization } from "../../api/organizationAPI";
import { useAuth } from "@clerk/clerk-react";
import { InputBar } from "../InputBar";

const NewOrganization: React.FC = () => {
  const [title, setTitle] = useState("");
  const { getToken } = useAuth();

  const mutation = useMutation({
    mutationFn: () =>
      (async () => {
        const freshToken = await getToken();
        if (!freshToken) {
          throw new Error("could not generate a token for this request");
        }
        return createOrganization(title, freshToken);
      })(),
    onSuccess: () => {
      // fix me-add a refresh for organization list here.
      console.log("successfully created a new organization");
    },
    onError: (error: Error) => {
      console.error("Organization error", error);
    },
  });

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    mutation.mutate();
  };

  return (
    <form onSubmit={handleSubmit} className="font-sans m-5">
      <h2 className="text-2xl">New Organization</h2>

      <div>
        <label htmlFor="title">Title:</label>
        <InputBar
          id="title"
          type="title"
          value={title}
          setValue={setTitle}
          placeholder="Enter title"
        />
      </div>

      <button disabled={mutation.isPending} className="btn-henna">
        Create New Organization
      </button>
      {mutation.isPending && (
        <p>We are working on creating your organization...</p>
      )}
      {mutation.isError && <p>{mutation.error.message}</p>}
      {mutation.isSuccess && <p>Organization creation successful!</p>}
    </form>
  );
};

export default NewOrganization;
