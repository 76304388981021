import React from "react";

interface DividerProps {
  handleClick?: () => void;
  text: string; // Add width prop
  type?: "submit" | "reset" | "button" | undefined;
  disabled?: boolean;
}

const ButtonOutline: React.FC<DividerProps> = ({
  handleClick,
  text,
  type,
  disabled,
}) => {
  return (
    <div>
      <button
        onClick={handleClick ?? undefined}
        type={type ?? undefined}
        disabled={disabled ?? false}
        className={`rounded-full w-[200px] px-3 py-2 focus:outline-none focus:ring-2 focus:ring-henna-green border border-search-bar-elements ${disabled && "border-slate-300 text-slate-300"}`}
      >
        {text}
      </button>
    </div>
  );
};

export default ButtonOutline;
