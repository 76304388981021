import React, { useState } from "react";
import Layout from "../../components/layout/ChatLayout";
import OrganizationList from "../../components/chat/OrganizationList";
import NewOrganization from "../../components/chat/NewOrganization";
import CollectionList from "../../components/chat/CollectionList";

// interface AsideProps {
//   currentOrganizationId: string;
//   handleChangeConversationId: (conversationId: string) => void;
//   handleChangeOrganizationId: (organizationId: string) => void;
// }

// The Aside component
const Aside: React.FC = () => (
  <div className="flex flex-col">
    <div>
      <button className="text-lg">Organizations</button>
    </div>
    <div>
      <span className="text-lg">Values</span>
    </div>
  </div>
);
const AdminPage: React.FC = () => {
  // const [currentConversationId, setCurrentConversationId] = useState('')
  const [currentOrganizationId, setCurrentOrganizationId] = useState("");
  const [showOrganization] = useState(true);
  const [showValues] = useState(true);

  // function handleChangeConversationId(id: string) {
  //   setCurrentConversationId(id)
  // }
  function handleChangeOrganizationId(id: string) {
    setCurrentOrganizationId(id);
  }
  const renderAside = () => (
    <Aside
    // currentOrganizationId={currentOrganizationId}
    // handleChangeConversationId={setCurrentConversationId}
    // handleChangeOrganizationId={setCurrentOrganizationId}
    />
  );
  return (
    <Layout aside={renderAside}>
      <h1>Admin Page</h1>
      {showOrganization && (
        <div>
          <p>This page can add/delete organizations and teams</p>
          <p>
            {`This page lists all of the organizations that you are a part of. If
            you do not see any organizations, then click "New Organization". You can also view all of the teams connected to an organization by clicking "view teams" next to each organization.  If
            you don't have an organization or team yet, You should click on
            henna-app, (or another organization), then click on view teams, and
            then click join team 1. Then you should be ready to create
            conversations and send messages.`}
          </p>
          <NewOrganization />
          <OrganizationList
            setCurrentOrganizationId={handleChangeOrganizationId}
          />
        </div>
      )}
      {showValues && (
        <div>
          <CollectionList></CollectionList>
        </div>
      )}
    </Layout>
  );
};

export default AdminPage;
