import CircularIndeterminate from "../CircularIndeterminate";
interface ConversationListProps {
  setCurrentConversationId: (str: string) => void;
  conversationsData: any;
  conversationsIsPending: boolean;
  conversationsError: any;
  currentConversationId: string | null | undefined;
}
const ConversationList: React.FC<ConversationListProps> = ({
  setCurrentConversationId,
  conversationsData,
  conversationsIsPending,
  conversationsError,
  currentConversationId,
}) => {
  return (
    <div className="m-5">
      <h2 className="font-sans text-2xl">Your Conversations</h2>
      {conversationsIsPending && <CircularIndeterminate />}
      {conversationsError && (
        <p>An error has occurred: {conversationsError.message}</p>
      )}
      <div className="flex flex-col gap-2 border border-solid-2 overflow-y-scroll">
        {conversationsData &&
          conversationsData?.map((conversation: any, index: number) => (
            <div key={index}>
              <button
                key={index}
                onClick={() => setCurrentConversationId(conversation.id)}
                className={`text-left p-1 ${currentConversationId === conversation.id ? "font-bold" : "font-normal"}`}
              >
                {conversation.title}
              </button>
            </div>
          ))}
      </div>
    </div>
  );
};

export default ConversationList;
