import axios, { AxiosError } from "axios";
import { Team } from "../types/models/Team";

const backendURL =
  process.env.REACT_APP_BACKEND_URL ?? "http://localhost:5000/api";

export async function createTeam(
  organizationId: string,
  title: string,
  token: string,
): Promise<Team> {
  try {
    const response = await axios.post(
      `${backendURL}/organization/${organizationId}/team`,
      { title },
      {
        headers: {
          "Content-Type": "application/json", // Example header
          Authorization: `Bearer ${token}`,
        },
      },
    );
    if (response?.data?.team) {
      return response?.data?.team;
    } else {
      throw new Error("did not return a team");
    }
  } catch (error: any) {
    if (error instanceof AxiosError) {
      const message = error.response?.data.message;
      throw new Error(message);
    } else {
      throw new Error(error.message);
    }
  }
}
export async function fetchTeams(
  organizationId: string,
  token: string | null,
): Promise<Team[]> {
  if (!token) {
    throw new Error("Auth token was not provided");
  }
  const response = await axios.get(
    `${backendURL}/organization/${organizationId}/team`,
    {
      headers: {
        "Content-Type": "application/json", // Example header
        Authorization: `Bearer ${token}`,
      },
    },
  );
  if (response?.data?.teams) {
    return response?.data?.teams;
  } else {
    throw new Error("did not return a team list");
  }
}
export async function fetchTeamById(
  organizationId: string,
  teamId: string,
  token: string,
): Promise<Team> {
  const response = await axios.get(
    `${backendURL}/organization/${organizationId}/team/${teamId}`,
    {
      headers: {
        "Content-Type": "application/json", // Example header
        Authorization: `Bearer ${token}`,
      },
    },
  );
  if (response?.data?.team) {
    return response?.data?.team as Team;
  } else {
    throw new Error("did not return a team");
  }
}

export async function updateTeam(
  organizationId: string,
  teamId: string,
  updateData: { title?: string },
  token: string,
): Promise<Team> {
  const response = await axios.put(
    `${backendURL}/organization/${organizationId}/team/${teamId}`,
    updateData,
    {
      headers: {
        "Content-Type": "application/json", // Example header
        Authorization: `Bearer ${token}`,
      },
    },
  );
  if (response?.data?.team) {
    return response.data.team;
  } else {
    throw new Error("Failed to update the team");
  }
}

export async function deleteTeam(
  organizationId: string,
  teamId: string,
  token: string,
): Promise<void> {
  const response = await axios.delete(
    `${backendURL}/organization/${organizationId}/team/${teamId}`,
    {
      headers: {
        "Content-Type": "application/json", // Example header
        Authorization: `Bearer ${token}`,
      },
    },
  );
  if (response.status !== 200) {
    throw new Error("Failed to delete the team");
  }
}
export async function generateTeamValueSummary(
  organizationId: string,
  teamId: string,
  token: string,
): Promise<void> {
  const response = await axios.post(
    `${backendURL}/organization/${organizationId}/team/${teamId}/generate-values`,
    {},
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    },
  );
  if (response.status !== 200) {
    throw new Error("Failed to delete the team");
  }
}
