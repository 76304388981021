import React from "react";
import { Navigate } from "react-router-dom";
import { useUser } from "@clerk/clerk-react";
import CircularIndeterminate from "../CircularIndeterminate";

export const ProtectedRouteIsLoggedIn = ({
  children,
}: {
  children: JSX.Element;
}) => {
  const { isSignedIn, isLoaded } = useUser();
  if (!isLoaded) {
    return <CircularIndeterminate />;
  }
  if (!isSignedIn && isLoaded) {
    // Redirect to the login page if not signed in
    return <Navigate to="/auth" replace />;
  }
  // If signed in, proceed to the requested page
  return children;
};
