import { SignOutButton } from "@clerk/clerk-react";
import { useState, useEffect, useRef, useContext } from "react";
// import { ReactComponent as NotificationBell } from "./logos/notificationBell.svg";
import { useUser } from "@clerk/clerk-react";

import { MyContext } from "../context/Context";
import { Card, CardContent, CardTitle, CardHeader } from "./ui/card";
import { CardFooter } from "react-bootstrap";
import { Link } from "react-router-dom";
export interface PressCardProps {
  header: string;
  imageLink: string;
  body: string;
  tags: string[];
  author: string;
  date: string;
  externalLink: string;
}
const PressCard = (props: PressCardProps) => {
  // Close dropdown if clicked outside
  const { header, imageLink, body, tags, author, date, externalLink } = props;

  return (
    <div className="h-full">
      <Card className="max-w-[460px] border border-henna-green-accent rounded-tl-xxxl rounded-br-xxxl h-full flex flex-col gap-4">
        <CardHeader className="p-0">
          <img src={imageLink} />
        </CardHeader>
        <CardContent className="flex flex-col gap-4">
          <div className="flex mx-2 my-2 gap-2">
            {tags.map((tag, idx) => (
              <span className="tag" key={idx}>
                {tag}
              </span>
            ))}
          </div>
          <Link to={externalLink}>
            <CardTitle className="header-henna-5 font-bold">{header}</CardTitle>
          </Link>

          <p className="">{body}</p>
        </CardContent>
        <CardFooter className="flex gap-2 w-full px-6 pb-2 mt-auto mb-1">
          <div className="flex gap-3 w-full">
            <img className="w-5 h-5" src="/user.png" alt="user icon" />
            <span className="text-sm">{author}</span>
          </div>
          <div className="flex gap-3 w-full">
            <img className="h-5 w-5" src="/calendar.png" alt="calendar" />
            <span className="text-sm">{date}</span>
          </div>
        </CardFooter>
      </Card>
    </div>
  );
};
// Figure out how to style the SignOutButton component later so we don't have to wrap it with a div for styling
export default PressCard;
