import React from "react";
import classNames from "classnames";

interface DividerProps {
  dividingMessage?: string;
  className?: string;
}

const Divider: React.FC<DividerProps> = ({
  dividingMessage,
  className = "w-11/12",
}) => {
  const dividerClass = classNames(`${className} mx-auto divider`, {
    "no-text": !dividingMessage, // Add 'no-text' class if no dividingMessage
  });

  return <div className={dividerClass}>{dividingMessage}</div>;
};

export default Divider;
