import axios, { AxiosError } from "axios";
import { Conversation } from "../types/models/Conversation";

export interface ConversationInfo {
  organizationId: string;
  teamId: string;
  token: string;
  type: string;
}
const backendURL = process.env.REACT_APP_BACKEND_URL;

export async function createConversation(
  conversationInfo: ConversationInfo,
): Promise<Conversation> {
  const response = await axios.post(
    `${backendURL}/organization/${conversationInfo.organizationId}/team/${conversationInfo.teamId}/conversation`,
    { type: conversationInfo.type },
    {
      headers: {
        "Content-Type": "application/json", // Example header
        Authorization: `Bearer ${conversationInfo.token}`,
      },
    },
  );
  console.log("this is the response from api", response);
  if (response?.data?.conversation) {
    return response?.data?.conversation;
  } else {
    throw new Error("did not return a conversation");
  }
}
export async function fetchConversations(
  organizationId: string,
  teamId: string,
  token: string | null,
): Promise<Conversation[]> {
  try {
    if (!token) {
      throw new Error("Auth token was not provided");
    }
    const response = await axios.get(
      `${backendURL}/organization/${organizationId}/team/${teamId}/conversation`,
      {
        headers: {
          "Content-Type": "application/json", // Example header
          Authorization: `Bearer ${token}`,
        },
      },
    );
    if (response?.data?.conversations) {
      return response?.data?.conversations;
    } else {
      throw new Error("did not return a conversation list");
    }
  } catch (error: any) {
    if (error instanceof AxiosError) {
      const message = error.response?.data.message;
      throw new Error(message);
    } else {
      throw new Error(error.message);
    }
  }
}
export async function fetchConversationById(
  organizationId: string,
  teamId: string,
  conversationId: string,
  token: string | null,
): Promise<Conversation> {
  try {
    if (!token) {
      throw new Error("Auth token was not provided");
    }
    const response = await axios.get(
      `${backendURL}/organization/${organizationId}/team/${teamId}/conversation/${conversationId}`,
      {
        headers: {
          "Content-Type": "application/json", // Example header
          Authorization: `Bearer ${token}`,
        },
      },
    );
    if (response?.data?.conversation) {
      return response?.data?.conversation as Conversation;
    } else {
      throw new Error("did not return a conversation");
    }
  } catch (error: any) {
    if (error instanceof AxiosError) {
      const message = error.response?.data.message;
      throw new Error(message);
    } else {
      throw new Error(error.message);
    }
  }
}

export async function updateConversation(
  organizationId: string,
  teamId: string,
  conversationId: string,
  updateData: { title?: string; stage?: string },
  token: string | null,
): Promise<Conversation> {
  if (!token) {
    throw new Error("Auth token was not provided");
  }
  const response = await axios.put(
    `${backendURL}/organization/${organizationId}/team/${teamId}/conversation/${conversationId}`,
    updateData,
    {
      headers: {
        "Content-Type": "application/json", // Example header
        Authorization: `Bearer ${token}`,
      },
    },
  );
  if (response?.data?.conversation) {
    return response.data.conversation;
  } else {
    throw new Error("Failed to update conversation");
  }
}

export async function deleteConversation(
  organizationId: string,
  teamId: string,
  conversationId: string,
  token: string,
): Promise<void> {
  if (!token) {
    throw new Error("Auth token was not provided");
  }
  const response = await axios.delete(
    `${backendURL}/organization/${organizationId}/${teamId}/conversation/${conversationId}`,
    {
      headers: {
        "Content-Type": "application/json", // Example header
        Authorization: `Bearer ${token}`,
      },
    },
  );
  if (response.status !== 200) {
    throw new Error("Failed to delete conversation");
  }
}
