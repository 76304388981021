import { useAuth } from "@clerk/clerk-react";
import { useQueryClient, useMutation, useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { deleteCollection, fetchCollections } from "../../api/collectionAPI";
import { createJoinRequest } from "../../api/joinRequestAPI";
import { JoinRequest } from "../../types/models/JoinRequest";

const JoinRequestForm: React.FC = () => {
  const { getToken } = useAuth();
  const [organizationTitle, setOrganizationTitle] = useState("");
  const [teamTitle, setTeamTitle] = useState("");

  const queryClient = useQueryClient();
  const joinRequestMutation = useMutation({
    mutationFn: (info: { organizationTitle: string; teamTitle: string }) =>
      (async () => {
        const fetchedToken = await getToken();
        if (!fetchedToken) {
          throw new Error("Your token could not be accessed");
        }
        return createJoinRequest(
          fetchedToken,
          info.organizationTitle,
          info.teamTitle,
        );
      })(),
    onSuccess: (joinRequest: JoinRequest) => {
      // setInviteURL(url);
    },

    onError: (error: Error) => {
      console.error("Conversation error", error);
    },
  });

  const handleSubmitJoinOrgAndTeam = (e: React.FormEvent) => {
    e.preventDefault();
    joinRequestMutation.mutate({ organizationTitle, teamTitle });
  };

  return (
    <form
      className="flex flex-col gap-2 p-5 m-2 border-2 border-henna-border-gray max-w-[830px]"
      onSubmit={handleSubmitJoinOrgAndTeam}
    >
      <p className="my-2">
        Welcome to Henna! To get started, first you need to submit a request to
        join an organization and Team. Enter the name of your organization and
        team below.
      </p>

      <div className="grid grid-cols-4 my-2">
        <label htmlFor="organization-input" className="col-span-2">
          Organization Name:{" "}
        </label>
        <input
          id="organization-input"
          value={organizationTitle}
          onChange={(e) => setOrganizationTitle(e.target.value)}
          className="col-span-2 border shadow border-henna-border-gray focus:outline-none focus:ring-2 focus:ring-henna-green focus:border-transparent"
        />
      </div>
      <div className="grid grid-cols-4 my-2">
        <label htmlFor="team-input" className="col-span-2">
          Team Name:{" "}
        </label>
        <input
          id="team-input"
          value={teamTitle}
          onChange={(e) => setTeamTitle(e.target.value)}
          className="col-span-2 border shadow border-henna-border-gray focus:outline-none focus:ring-2 focus:ring-henna-green focus:border-transparent"
        />
      </div>
      <div className="my-2">
        <button className="btn-henna">Submit</button>
      </div>
      {joinRequestMutation.isPending && <p>Sending Your Request...</p>}
      {joinRequestMutation.isError && (
        <p>{joinRequestMutation.error.message}</p>
      )}
      {joinRequestMutation.isSuccess && (
        <p>Your request has been successfully created.</p>
      )}
    </form>
  );
};

export default JoinRequestForm;
