import React, { useContext } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useUser } from "@clerk/clerk-react";
import { MyContext } from "../../context/Context";

export const ProtectedRouteIsTeamLeader = ({
  children,
}: {
  children: JSX.Element;
}) => {
  const { isSignedIn, isLoaded } = useUser();
  const navigate = useNavigate();
  const { user } = useContext(MyContext);
  if (!isSignedIn) {
    // Return a loading indicator, or null for a blank page
    // You can replace this with a custom loading component if you have one
    navigate("/auth");
  }
  if (!user) {
    return <div>Loading your user data...</div>; // Example loading indicator
  }
  const acceptedRoles = [
    // "DEFAULTUSER",
    "TEAMLEADER",
    "ORGANIZATIONLEADER",
    "ADMIN",
    "GLOBALADMIN",
  ];

  if (!isSignedIn && isLoaded) {
    return <Navigate to="/auth" replace />;
  }
  if (user && user.role) {
    if (acceptedRoles.includes(user.role.title)) {
      return children;
    }
  }

  return <Navigate to="/chat/dashboard" replace />;
};
